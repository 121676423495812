<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Introductie tekst" subtitle="Je persoonlijke introductie tekst voor al je CV's" />

    <div class="mt-10 sm:mt-0" v-if="!loading">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">General information</h3>
            <p class="mt-1 text-sm text-gray-600">
              More help here.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form @submit.prevent="handleSave" method="POST">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  
                  <div class="col-span-6 sm:col-span-3">
                    <label for="fist_name" class="block text-sm font-medium text-gray-700">First name</label>
                    <input type="text" name="fist_name" id="fist_name" autocomplete="fist_name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="data.first_name">
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="last_name" class="block text-sm font-medium text-gray-700">Last name</label>
                    <input type="text" name="last_name" id="last_name" autocomplete="last_name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="data.last_name">
                  </div>

                  <div class="col-span-6 sm:col-span-6">
                    <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
                    <TinyMceEditor v-model="data.description" :model_id="data.id" :simlpe="true" />
                  </div>
                  
                </div>
              </div>
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                  <svg v-show="loading" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
                  {{loading ? 'saving': 'save'}}
                </button>
              </div>
            </div>
          </form>
          
        </div>
          
      </div>
    </div>

    <div class="hidden sm:block" aria-hidden="true">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>


        <div v-if="data">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">Slide image</h3>
                <p class="mt-1 text-sm text-gray-600">
                  Upload a high resolution image. The image will be scaled automatically.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                    
                    <div>
                      <Uploader model="User" :model_id="data.id" @update-add="myMethod" />  
                      <PhotoList model="User" :photos="data.Photos" :id="data.id" @update-delete="myMethod" />
                    </div>
                  </div>
                  
                </div>
              
            </div>
          </div>

        </div>
  </main>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router';
import CvService from '../../../../services/admin/cv/service';
import TinyMceEditor from '../../../../components/admin/generic/TinyMceEditor.vue';
import PhotoList from '../../../../components/admin/generic/PhotoList';
import Uploader from '../../../../components/admin/generic/Uploader';

export default {
  name: 'editeducation',
  components: {
    TinyMceEditor,
    PhotoList,
    Uploader
  },
  setup() {
    const route = useRoute();
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const user_id = ref(route.params.user_id);

    function fetchData() {
      loading.value = true;
      // I prefer to use fetch
      // you can use use axios as an alternative
      return CvService.getIntro(user_id.value).then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.data;
        })
        .then(json => {
          // set the response data
          data.value = json;
        })
        .catch(err => {
          error.value = err;
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then(json => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }
    onMounted(() => {
      fetchData();
    });

    return {
      data,
      user_id,
      loading,
      error
    };
  },
  methods: {
    async handleSave() {
      this.loading = true;
      CvService.saveIntro(this.data);
      this.emitter.emit('notification', {type: 'success', message: 'Updated', description: 'The introduction has been saved succesfully'});
      this.loading = false;
    },
    async myMethod() {
      console.log("Refreshing attachments");
      const data = await CvService.getIntro(this.user_id); 
      this.data = data.data;
    }
  }
}
</script>